/* Основной стиль для контейнера "О нас" */
.about-container {
    max-width: 800px; /* Максимальная ширина контейнера */
    margin: 40px auto; /* Центрирование контейнера с отступом сверху и снизу */
    padding: 20px; /* Внутренние отступы */
    background-color: #f8f8f8; /* Светлый фон */
    border-radius: 15px; /* Закругленные углы */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Тень */
}

/* Заголовок "О нас" */
.about-container h1 {
    font-size: 28px; /* Размер шрифта заголовка */
    margin-bottom: 20px; /* Отступ снизу для заголовка */
    text-align: center; /* Центрируем заголовок */
}

/* Контейнер для содержимого */
.about-content {
    background-color: #ffffff; /* Белый фон для содержимого */
    padding: 20px; /* Внутренние отступы */
    border-radius: 10px; /* Закругленные углы у содержимого */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Тень */
}

/* Небольшие отступы для абзацев */
.about-content p {
    margin: 10px 0; /* Отступы для параграфов */
}

/* Небольшие отступы для списка */
.about-content ul {
    margin: 10px 0; /* Отступы для списка */
    padding-left: 20px; /* Отступ слева для маркированного списка */
}

/* Стили для элементов списка */
.about-content li {
    margin-bottom: 5px; /* Отступ снизу для элементов списка */
}
