body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 120vh; /* Задает минимальную высоту контейнера */
}

main {
  flex: 1; /* Гарантирует, что основной контент занимает всё доступное пространство */
}
