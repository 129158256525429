.hero {
  text-align: center;
  padding: 100px 20px;
  background: linear-gradient(135deg, #ffffff, #ffffff);
  color: rgb(0, 0, 0);
  border-radius: 10px;
  margin: 20px;
  box-shadow: 0 10px 20px rgb(90, 90, 90);
  animation: fadeIn 1s ease-in-out;
}

.cta-button {
  padding: 15px 40px;
  font-size: 1.2rem;
  background-color: #000000;
  border: none;
  border-radius: 50px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.cta-button:hover {
  background-color: #979191;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}