/* Основной стиль для шапки */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #000000; /* Черный фон */
  color: #ffffff; /* Белый текст */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Тень */
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-title {
  font-size: 35px;
  margin-right: 10px;
  font-weight: bold; /* Жирный текст */
}

.logo-svg {
  width: 40px; /* Ширина SVG логотипа */
  height: auto; /* Пропорциональная высота */
}

.nav {
  display: flex;
  gap: 20px; /* Отступ между элементами навигации */
}

.nav-link, .nav-button {
  display: flex;
  align-items: center; /* Центрируем текст по вертикали */
  justify-content: center; /* Центрируем текст по горизонтали */
  font-size: 18px;
  padding: 10px 15px; /* Внутренние отступы */
  border-radius: 30px; /* Закругленные углы */
  transition: background-color 0.3s ease, color 0.3s ease; /* Плавный переход */
  text-decoration: none; /* Убираем подчеркивание */
}

/* Стиль для ссылок навигации */
.nav-link {
  color: #ffffff; /* Цвет текста */
  border: 3px solid #ffffff; /* Белая рамка */
  background-color: transparent; /* Прозрачный фон */
}

.nav-link:hover {
  background-color: #918f8d; /* Цвет фона при наведении */
  color: #ffffff; /* Цвет текста при наведении */
}

/* Стиль для кнопки "О нас" */
.nav-button {
  background-color: #000000; /* Цвет фона кнопки */
  border: 3px solid #ffffff; /* Белая рамка */
  color: #ffffff; /* Белый текст */
}

.nav-button:hover {
  background-color: #918f8d; /* Цвет фона при наведении */
  color: #ffffff; /* Цвет текста при наведении */
}

/* Стиль для кнопки "Купить" */
.nav-buy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: 10px 20px; /* Немного больше отступ */
  border-radius: 30px;
  background-color: #000000; /* Оранжевый фон */
  color: #ffffff; /* Белый текст */
  border: 3px solid #ffffff; /* Оранжевая рамка */
  transition: background-color 0.3s ease, color 0.3s ease;
  text-decoration: none; /* Убираем подчеркивание */
}

.nav-buy-button:hover {
  background-color: #918f8d; /* Более яркий оранжевый при наведении */
  border-color: #ffffff; /* Меняем цвет рамки при наведении */
  color: #ffffff; /* Белый текст остаётся */
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .header {
    flex-direction: column; /* Колонка для мобильных */
    align-items: flex-start; /* Выравнивание по левому краю */
  }

  .nav {
    flex-direction: column; /* Вертикальная навигация */
    gap: 10px; /* Уменьшаем промежуток между элементами навигации */
  }

  .nav-link, .nav-button, .nav-buy-button {
    width: 100%; /* Кнопки занимают всю ширину на мобильных */
    text-align: center; /* Центрируем текст внутри кнопок */
  }
}
