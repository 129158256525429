.footer {
  padding: 20px;
  background-color: #000000;
  color: white;
  text-align: center;
  position: relative;
  width: 100%; /* Полное покрытие ширины */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: auto;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.3); /* Добавление тени (опционально) */
}

.footer-nav {
  display: flex;
  justify-content: center;
  gap: 20px; /* Промежуток между элементами меню */
  margin-top: 10px;
}

.footer-nav a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
  white-space: nowrap; /* Предотвращает перенос текста */
}

.footer-nav a:hover {
  color: #00c3ff;
}

/* Сброс отступов и полей для body */
body {
  margin: 0; /* Убираем стандартные отступы браузера */
  padding: 0;
  overflow-x: hidden; /* Скрываем горизонтальную прокрутку */
  font-family: Arial, sans-serif; /* Задайте шрифт по умолчанию для всей страницы */
}