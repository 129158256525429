.features {
  padding: 50px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.features-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
}

.feature-item {
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.74);
  flex-basis: 20%;
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-10px);
}

.feature-item h3 {
  margin-bottom: 10px;
  color: #000000;
}

.feature-item p {
  color: #555;
}